import 'lazysizes'

import { staticFile } from '@common/utils'
import classNames from 'classnames'
import NextImage, {
  ImageLoader,
  ImageProps as NextImageProps,
} from 'next/image'
import React, { useEffect, useState } from 'react'

interface ImageProps {
  path: string
  name: string
  alt: string
  format?: string
  fallbackFormat?: string
  type?: string
  className?: string
}

/**
 * @deprecated Use FastlyImage instead
 */
export const Image = ({
  path,
  name,
  alt,
  className,
  format = '.webp',
  fallbackFormat = '.png',
  type = 'image/webp',
}: ImageProps) => (
  <picture>
    <source srcSet={staticFile(path + name + format)} type={type} />
    <img
      src={staticFile(path + name + fallbackFormat)}
      alt={alt}
      className={className}
    />
  </picture>
)

/**
 * @deprecated Use FastlyImage instead
 */
export const LazyImage = ({
  path,
  name,
  alt,
  className,
  format = '.webp',
  fallbackFormat = '.png',
  type = 'image/webp',
}: ImageProps) => (
  <picture>
    <source data-srcset={staticFile(path + name + format)} type={type} />
    <img
      src={staticFile(path + name + '-lqip' + fallbackFormat)}
      alt={alt}
      data-src={staticFile(path + name + fallbackFormat)}
      className={classNames('lazyload', className)}
    />
  </picture>
)

interface APIImageProps {
  src: string
  alt: string
  className?: string
  width?: number
  height?: number
}

export const LazyAPIImage = ({
  src,
  className,
  alt,
  width,
  height,
}: APIImageProps) => (
  <img
    alt={alt}
    data-src={src}
    width={width}
    height={height}
    className={`lazyload ${className}`}
  />
)

const fastlyIOLoader: ImageLoader = ({ src, width, quality }) => {
  let url =
    staticFile(src) + `?auto=webp&optimize=medium&width=${width.toFixed(0)}`

  if (quality) url += `quality=${quality.toFixed(0)}`

  return url
}

/** Fetches images via Fastly IO */
export const FastlyImage = (props: NextImageProps) => (
  <NextImage {...props} loader={fastlyIOLoader} />
)

type PreloadImagesProps = {
  images: {
    url: string
    height: number
    width: number
  }[]
  timeout?: number
}

export const PreloadImagesInSeries = React.memo(
  ({ images, timeout = 0 }: PreloadImagesProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [startPreloading, setStartPreloading] = useState(false)

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setStartPreloading(true)
      }, timeout)
      return () => clearTimeout(timeoutId)
    }, [])

    const handleOnLoadingComplete = () => {
      if (currentImageIndex >= images.length) return undefined
      setCurrentImageIndex((prev) => prev + 1)
    }

    return (
      <div
        className="absolute w-0 h-0 pointer-events-none"
        style={{
          visibility: 'hidden',
          zIndex: -1,
          top: '-9999px',
          left: '-9999px',
        }}
      >
        {startPreloading && currentImageIndex < images.length && (
          // Fastly image is used to make the preload request call
          // indentical to the one made for the actualy image.
          // FastlyImage component adds query params mentioning width
          // and other attributes. This makes sure preloaded images are
          // requested with the same query.
          <FastlyImage
            src={images[currentImageIndex]?.url}
            height={images[currentImageIndex]?.height}
            width={images[currentImageIndex]?.width}
            onLoadingComplete={handleOnLoadingComplete}
            priority
          />
        )}
      </div>
    )
  }
)
