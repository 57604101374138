import { CAREERS_LINK } from '@lib/constants/common'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage } from '@lingui/macro'

export type ResourceLinkType = {
  title: LinguiJSMessageDescriptor
  link: string
  imagePath: string
}

export const RESOURCE_LINKS: ResourceLinkType[] = [
  {
    title: defineMessage({
      id: 'footer.resources.codingCompetitions',
      message: 'Coding Competitions',
    }),
    link: 'https://www.codingal.com/competitions/',
    imagePath: 'competitions.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingQuizzes',
      message: 'Coding Quizzes',
    }),
    link: 'https://www.codingal.com/quizzes/',
    imagePath: 'quizzes.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingMasterClasses',
      message: 'Coding Masterclasses',
    }),
    link: 'https://www.codingal.com/resources/masterclasses/',
    imagePath: 'masterclass.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingWebinar',
      message: 'Coding Webinars',
    }),
    link: 'https://www.codingal.com/resources/webinars/',
    imagePath: 'webinars.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingWorksheets',
      message: 'Coding Worksheets',
    }),
    link: 'https://www.codingal.com/coding-for-kids/diy-worksheets/',
    imagePath: 'worksheets.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingBlog',
      message: 'Coding Blog',
    }),
    link: 'https://www.codingal.com/blog/',
    imagePath: 'blog.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingGuides',
      message: 'Coding Guides',
    }),
    link: 'https://www.codingal.com/resources/coding-guides/',
    imagePath: 'guide.png',
  },
  {
    title: defineMessage({
      id: 'footer.resources.codingCourses',
      message: 'Coding Courses',
    }),
    link: 'https://www.codingal.com/courses/',
    imagePath: 'courses.png',
  },
]

export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/codingal.education',
  twitter: 'https://twitter.com/codingal',
  youtube: 'https://www.youtube.com/c/CodingalEducation',
  linkedIn: 'https://www.linkedin.com/company/codingal',
  instagram: 'https://www.instagram.com/codingal.education/',
}

export type CodingGuideType = {
  title: LinguiJSMessageDescriptor
  link: string
}

export const TRENDING_ARTICLES: CodingGuideType[] = [
  {
    title: defineMessage({
      id: 'footer.codingGuides.codingForKids',
      message: 'Coding 101 for kids',
    }),
    link:
      'https://www.codingal.com/coding-for-kids/coding-guides/coding-101-the-ultimate-guide/',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.howToGetStarted',
      message: 'How can kids get started with coding',
    }),
    link:
      'https://www.codingal.com/coding-for-kids/coding-guides/how-can-kids-get-started-with-coding/',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.introToscratch',
      message: 'Introduction to Scratch coding',
    }),
    link:
      'https://www.codingal.com/coding-for-kids/blog/introduction-to-scratch-coding/',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.minecraft',
      message: 'Minecraft - a game or a coding platform?',
    }),
    link: 'https://resources.codingal.com/how-to-learn-coding-with-minecraft',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.blockCoding',
      message: 'Block Coding - An A to Z guide',
    }),
    link:
      'https://resources.codingal.com/block-coding-an-a-to-z-guide-updated-2021',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.womenInStem',
      message: 'Women in STEM',
    }),
    link: 'https://resources.codingal.com/women-in-stem-all-you-need-to-know',
  },
  {
    title: defineMessage({
      id: 'footer.codingGuides.pythonForKids',
      message: 'Python for Kids Guide',
    }),
    link:
      'https://www.codingal.com/resources/coding-guides/python-programming-for-kids-the-ultimate-guide-to-learn-python/',
  },
]

type CourseType = {
  courseName: LinguiJSMessageDescriptor
  courseLink: string
}

export const BEST_SELLING_COURSES: CourseType[] = [
  {
    courseName: defineMessage({ message: `Scratch Programming` }),
    courseLink: '/courses/scratch-programming/',
  },
  {
    courseName: defineMessage({ message: `Python for kids` }),
    courseLink: '/courses/python-for-kids/',
  },
  {
    courseName: defineMessage({ message: `Game Development` }),
    courseLink: '/courses/game-development/',
  },
  {
    courseName: defineMessage({ message: `Web Development` }),
    courseLink: '/courses/web-development/',
  },
  {
    courseName: defineMessage({ message: `App Development` }),
    courseLink: '/courses/app-development/',
  },
  {
    courseName: defineMessage({ message: `Data science for kids` }),
    courseLink: '/courses/data-science/',
  },
  {
    courseName: defineMessage({ message: `Roblox` }),
    courseLink: '/courses/roblox-coding/',
  },
  {
    courseName: defineMessage({ message: `AP Computer Science A` }),
    courseLink: '/courses/ap-computer-science-a/',
  },
  {
    courseName: defineMessage({ message: `IOI Algorithms Course` }),
    courseLink: '/courses/ioi-algorithms-course/',
  },
]

export const COMPANY_LINKS = [
  {
    label: defineMessage({ message: 'About us' }),
    link: 'https://www.codingal.com/about-us/',
  },
  {
    label: defineMessage({ message: 'Reviews and testimonials' }),
    link: 'https://www.codingal.com/reviews-and-testimonials/',
  },
  {
    label: defineMessage({ message: 'Curriculum' }),
    link: 'https://www.codingal.com/curriculum/',
  },
  {
    label: defineMessage({ message: 'Why Codingal' }),
    link: 'https://www.codingal.com/why-codingal/',
  },
  {
    label: defineMessage({ message: 'Careers' }),
    link: CAREERS_LINK,
  },
  {
    label: defineMessage({ message: 'School Partnerships' }),
    link: 'https://www.codingal.com/school-partnership/',
  },
  {
    label: defineMessage({ message: 'System check' }),
    link: 'https://www.codingal.com/system-check/',
  },
  {
    label: defineMessage({ message: 'Press releases' }),
    link: 'https://www.codingal.com/press/',
  },
  {
    label: defineMessage({ message: 'Become a teacher' }),
    link: 'https://www.codingal.com/teach/',
  },
]
