import ContactFold from '@components/common/footer/contact-fold'
import Divider from '@components/common/footer/divider'
import InternalLinksFold from '@components/common/footer/internal-links-fold'
import LocationFold from '@components/common/footer/location-fold'
import MissionFold from '@components/common/footer/mission-fold'
import ResourcesFold from '@components/common/footer/resources-fold'
import TermsAndPolicy from '@components/common/footer/terms-policy'
import { FastlyImage } from '@components/common/image'
import useDetectCountry from '@hooks/useDetectCountry'
import { LOGO_THEME } from '@lib/constants/common'
import { t } from '@lingui/macro'
const IMAGE_PATH = '/images/footer/'

const Footer = () => {
  const { countryCode } = useDetectCountry()

  return (
    <footer className="flex flex-col">
      <div className="px-5 py-10 rounded-b-lg primary-gradient pt-20 lg:pb-10 md:px-20">
        <div className="container md:px-6 mx-auto">
          <div className="flex flex-col items-center">
            <div className="flex flex-col justify-between w-full lg:flex-row">
              <div className="relative flex flex-col items-center justify-between w-full lg:flex-row">
                <FastlyImage
                  src={`${IMAGE_PATH}stem-logo.png`}
                  alt="STEM accredited educational experience"
                  height={94}
                  width={193}
                />
                <ContactFold countryCode={countryCode} />
              </div>
            </div>
          </div>
          <Divider />
          <ResourcesFold />
          <Divider />
          <InternalLinksFold />
          <Divider />
          <MissionFold />
          <LocationFold countryCode={countryCode} />
        </div>
      </div>
      <div className="px-10 mb-10 lg:mb-0">
        <div className="container flex flex-col items-center max-w-6xl mx-auto my-10 space-y-6 lg:flex-row lg:justify-between lg:my-6 lg:space-y-0">
          <TermsAndPolicy />
          <FastlyImage
            src={`/images/logos/logos-${LOGO_THEME}/logo-with-text.svg`}
            alt={t`Codingal logo`}
            height={25}
            width={95}
            className="w-1/2 pb-8 mx-auto lg:w-24 lg:mx-0 lg:ml-auto md:pb-0"
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
