import { FastlyImage } from '@components/common/image'
import useDetectCountry from '@hooks/useDetectCountry'
import { RESOURCE_LINKS } from '@lib/data/footer'
import { t, Trans } from '@lingui/macro'
const IMAGE_PATH = '/images/footer/'

const ResourcesFold = () => {
  const { countryCode } = useDetectCountry()
  return (
    <div className="flex flex-row flex-wrap lg:flex-no-wrap items-start my-10 justify-between">
      <div className="flex flex-col w-128 max-w-full mb-5">
        <a
          href="/coding-for-kids"
          className="flex items-start space-x-3 mb-5 md:mb-8"
        >
          <p className=" text-grey bg-yellow-200 px-3 font-600 rounded-md md:mt-3">
            <Trans id="footer.ned">New</Trans>
          </p>
          <p className="underline font-700 text-xl md:text-4xl text-white leading-tight">
            <Trans id="footer.codingForKidsResources">
              Coding for kids and teens resources
            </Trans>
          </p>
        </a>
        <p className="text-lg md:text-xl text-white">
          <Trans>
            8 free coding resources for kids and teens to start their coding
            journey
          </Trans>
        </p>
      </div>
      <div className="flex flex-wrap w-148 md:pl-10">
        {RESOURCE_LINKS.map((resources) => (
          <a
            key={resources.title.id}
            href={resources.link}
            className="text-white font-600 block align-middle rounded w-40 px-1 md:px-0 md:mx-1 my-5 hover:shadow-2xl"
          >
            <FastlyImage
              width={37}
              height={39}
              alt={t`${resources.title}`}
              src={`${IMAGE_PATH}${resources.imagePath}`}
            />
            <p>
              <Trans id={resources.title.id}></Trans>
            </p>
          </a>
        ))}
        {countryCode === 'US' && (
          <a
            href="/kids-teens-coding-camps/summer/"
            className="text-white font-600 block align-middle rounded w-40 px-1 md:px-0 md:mx-1 my-5 hover:shadow-2xl"
          >
            <FastlyImage
              width={37}
              height={39}
              alt={t`Summer Coding Camp`}
              src={`${IMAGE_PATH}masterclass.png`}
            />
            <p>
              <Trans>Summer Coding Camp</Trans>
            </p>
          </a>
        )}
      </div>
    </div>
  )
}

export default ResourcesFold
